.loaderSection{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderSectionofManageEnterprise{
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperloader {
    width: 200px;
    height: 60px;
    position: relative;
    z-index: 1;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #4BA3C3;
    left: 15%;
    transform-origin: 50%;
    animation: circle7124 .5s alternate infinite ease;
  }
  
  @keyframes circle7124 {
    0% {
      top: 60px;
      height: 5px;
      border-radius: 50px 50px 25px 25px;
      transform: scaleX(1.7);
    }
  
    40% {
      height: 20px;
      border-radius: 50%;
      transform: scaleX(1);
    }
  
    100% {
      top: 0%;
    }
  }
  
  .circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
  }
  
  .circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
  }
  
  .shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(66, 83, 94, 0.9);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow046 .5s alternate infinite ease;
  }
  
  @keyframes shadow046 {
    0% {
      transform: scaleX(1.5);
    }
  
    40% {
      transform: scaleX(1);
      opacity: .7;
    }
  
    100% {
      transform: scaleX(.2);
      opacity: .4;
    }
  }
  
  .shadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
  }
  
  .shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
  }



  .zoundslike_enterprise_words_button_syn {
    width: 24%;
    text-align: center;
    font-weight: bold;
    background: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #1b2123b7;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
    box-shadow:  rgba(50, 50, 93, 0.25) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    @media only screen and (max-width: 400px){
      font-size: 12px;
    }
  }
  
  .zoundslike_enterprise_words_button_active_class_syn {
    width: 24%;
    text-align: center;
    font-weight: bold;
    background: #4ba3c3;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    @media only screen and (max-width: 400px){
      font-size: 12px;
    }
  }

  .enterpriseCheckbox{
    background-color: transparent !important;
    color: black !important;;
  }

  .conditionModalInput{
    border: none !important;
  }
  .conditionModalInput:focus{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }


  /* Basic Tag Style */
.enterprisetag {
  background: #1d5a70;
  border-radius: 3px 0 0 3px;
  color: #fff;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}
.enterprisetag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.enterprisetag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #1d5a70;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.enterprisetag:hover {
  background-color: #4ba3c3;
  color: white;
  cursor: pointer;
}

.enterprisetag:hover::after {
   border-left-color: #4ba3c3; 
}